<template>
  <div class="content wrap">
    <div class="top_bar">
      <!-- <div class="title">
        <p>{{ $t("page.grab") }}</p>
      </div> -->
      <div class="content">
        <div class="top">
          <div class="left">{{ $t("orderCommission") }}</div>
          <div class="gt">&gt;</div>
          <div class="tag">
            {{
              `${$t("commission")}
                        ${getCommissionRate(levels[userInfo.level])}`
            }}
          </div>
        </div>
        <div class="content_item">
          <div class="items">
            <div class="title">{{ $t("todayIncome") }}</div>
            <div class="value">
              <!-- <span>{{ verConfig.moneyType }}</span
              >{{ Number(userInfo.today_complete_price).toFixed(2) || "0.00" }} -->
              {{
                $money(
                  Number(userInfo.today_complete_price).toFixed(2) || "0.00"
                )
              }}
            </div>
          </div>
          <div class="items">
            <div class="title">{{ $t("personalEarnings") }}</div>
            <div class="value">
              <!-- <span>{{ verConfig.moneyType }}</span
              >{{ Number(userInfo.complete_total_price).toFixed(2) || "0.00" }} -->
              {{
                $money(
                  Number(userInfo.complete_total_price).toFixed(2) || "0.00"
                )
              }}
            </div>
          </div>
        </div>
        <div class="content_item">
          <div class="items">
            <div class="title">{{ $t("completedOrders") }}</div>
            <div class="value">{{ userInfo.complete_order || 0 }}</div>
          </div>
          <!-- <div class="items">
            <div class="title">{{ $t("lockedOrders") }}</div>
            <div class="value">{{ userInfo.locked_order || 0 }}</div>
          </div> -->

          <!-- <div class="items">
            <div class="title">{{ $t("unfinishOrder") }}</div>
            <div class="value">
              {{
              userInfo
              ? userInfo.order_num -
              userInfo.complete_order -
              userInfo.locked_order
              : 0
              }}
            </div>
          </div> -->
        </div>
        <div class="content_item">
          <div class="items">
            <div class="title">{{ $t("frozonAmount") }}</div>
            <div class="value">
              <!-- <span>{{ verConfig.moneyType }}</span
              >{{ Number(userInfo.frozen_price).toFixed(2) || "0.00" }} -->
              {{ $money(Number(userInfo.frozen_price).toFixed(2) || "0.00") }}
            </div>
          </div>
          <div class="items">
            <div class="title">{{ $t("currentBalance") }}</div>
            <div class="value">
              <!-- <span>{{ verConfig.moneyType }}</span
              >{{ userInfo.money || "0.00" }} -->
              {{ $money(userInfo.money || "0.00") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 顶部 -->
    <!-- <div class="content_title">
      <img src="../../assets/new/undraw.fb398ee5.png" alt="" />
    </div> -->
    <!-- 中间四个格子 -->
    <!-- <div class="make-record">
      <div class="make-record-lists">
        <div
          class="make-record-item"
          v-for="(item, index) in makeRecordLists"
          :key="index"
        >
          <div class="make-item-content">
            <span>{{ item.label }}</span>
            <p class="spancolor" v-if="index == 2">{{ userInfo[item.value] }}</p>
            <p class="spancolor" v-else>
              {{ $money(userInfo[item.value]) }}
            </p>
          </div>
        </div>
      </div>
    </div> -->

    <!-- 轮播 -->
    <div class="marquee_wrap">
      <!-- <van-icon class="icon" :color="verConfig.noticeIconColor || '#ff3a3a'" name="volume" /> -->
      <i class="iconfont icon icon-chakangonggao01"></i>
      <HallMarquee class="marquee" :height="34">
        <template v-slot="{ item }">
          <div class="halloffame">
            <div class="notice-item-wrap">
              <div>
                <!-- <span>
                  <span class="spancolor">
                    {{ item.name }}
                  </span>
                  &nbsp;{{ $t("successWithdrawof") }} &nbsp;
                  <span class="spancolor"> {{ $money(item.money) }} </span>
                </span> -->
                <div>{{ $t('bannerText1', { name: item.name, money: item.money, money1: item.money }) }}</div>
                <div>{{ $t('bannerText2', { name: item.name }) }}</div>
              </div>
            </div>
          </div>
        </template>
      </HallMarquee>
      <!-- <marquee
        behavior="scroll"
        direction="up"
        class="marquee"
        scrollamount="3"
      >
        <div v-for="(item, index) in noticeArr" class="halloffame" :key="index">
          <div class="notice-item-wrap">
            <div>
              <span>
                <span class="spancolor">{{ item.name }}</span
                >&nbsp;{{ $t("successWithdrawof") }} &nbsp;<span
                  class="spancolor"
                >
                  {{ $money(item.money) }}
                </span></span
              >
            </div>
          </div>
        </div>
      </marquee> -->
    </div>

    <!-- 下单按钮 -->
    <div class="game-btn" @click="handleBuy" v-if="ver != 'amigo_vi'">
      <div class="game-btn-text-bc">
        {{ $t("newAdd.OrderImmediately") }}
      </div>
    </div>

    <!-- 文本域 -->
    <!-- <div class="textarea_wrap">
      <Field
        v-model="urlInput"
        rows="3"
        autosize
        type="textarea"
        :placeholder="$t('urlParsePlaceholder')"
        class="textarea"
      />
      <div class="submit_btn" @click="Identify">{{ $t("btn.Identify") }}</div>
    </div> -->

    <!-- 订单说明 -->
    <div class="make-synopsis">
      <div class="make-synopsis-title">
        <span>{{ $t("newAdd.OrderDescription") }}</span>
      </div>
      <div class="make-synopsis-content">
        <div>
          <template v-if="ver === 'myntra'">
            <p style="font-size: 16px">
              When you click auto-grab, the mall will automatically issue an
              order task. You need to complete the payment for the order. The
              order task needs to be completed within 60 minutes. After grabbing
              the order, please complete the task as soon as possible. <br />
              If you have any questions, please contact customer service.
            </p>
            <br />
          </template>
          <template v-else-if="ver === 'amigo_vi'">
            <p v-for="(item, index) in 7" :key="'p' + index">
              <strong><span style="color: #337fe5; font-size: 10px">({{ item }})</span></strong><span
                style="font-size: 10px">
                {{
                  $t("viGrabDesces." + item, {
                    rate: levels[userInfo.level]
                      ? levels[userInfo.level].rate
                      : 0,
                  })
                }}
                <!-- {{ $t("newAdd.DescriptionRate") }} </span><span style="color: #e23d6d; font-size: 10px">{{
                levels[userInfo.level]
                ? levels[userInfo.level].rate
                : 0
                }}%</span><span style="font-size: 10px">
                {{ $t("newAdd.DescriptionPeriod") }} -->
              </span>
            </p>
            <p>
              <br />
            </p>
          </template>
          <template v-else>
            <!-- <p>
              <span style="color: #337fe5; font-size: 10px"><strong>(1)</strong></span><span style="font-size: 10px">
                {{ $t("newAdd.CouldMatch") }} </span><span style="color: #e23d6d; font-size: 10px">
                {{
                userInfo.level ?
                levels[userInfo.level] ? levels[userInfo.level].order_num :
                orderNumObj[userInfo.level]
                : userInfo.level == 0
                ? 1
                : 0
                }}
              </span><span style="font-size: 10px">
                {{ $t("newAdd.OrderPerDay") }}</span>
            </p> -->
            <p>
              <br />
            </p>
            <p>
              <strong><span style="color: #337fe5; font-size: 14px" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }">(1)</span></strong><span :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }" style="font-size: 14px">
                {{ $t("newAdd.DescriptionRate") }} </span>
                <!-- <span style="color: #e23d6d; font-size: 10px">{{
                  levels[userInfo.level]
                  ? levels[userInfo.level].rate
                  : 0
                }}%</span><span :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }" style="font-size: 10px">
                {{ $t("newAdd.DescriptionPeriod") }}</span> -->
            </p>
            <p>
              <br />
            </p>
            <p>
              <span style="color: #337fe5; font-size: 14px" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }"><strong>(2)</strong></span><span :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }" style="font-size: 14px">
                {{ $t("newAdd.Description3") }}</span>
            </p>
            <p>
              <br />
            </p>
            <!-- <p>
              <span style="color: #337fe5; font-size: 10px" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }"><strong>(3)</strong></span><span style="font-size: 10px" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }">
                {{ $t("newAdd.Description4") }}</span>
            </p> -->
            <p>
              <br />
            </p>
            <span style="color: #e53333; font-size: 14px" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }">{{ $t("newAdd.Note") }} </span><span style="font-size: 14px" :style="lan == 'ar' ? { fontSize: 'calc(16rem / 16)' }: { }">{{
              $t("newAdd.DescriptionNote")
            }}</span><br />
          </template>
        </div>
      </div>
      <div class="make-synopsis-bottom"></div>
    </div>

    <!-- 弹窗 -->
    <div class="buy-box" v-if="isBuyBoxShow">
      <div class="cont">
        <div>
          <div class="goodsInfo">
            <div class="close" @click="closeBuy">
              <img src="../../assets/icon/close.png" alt="" />
            </div>
            <p>{{ $t("Cart.zzInfo.buyIng") }}······</p>
          </div>
          <div>
            <van-progress :percentage="percentage" stroke-width="20" />
          </div>
          <span class="space-text">
            {{ $t("newAdd.WaitDispatch") }}
          </span>
        </div>
      </div>
      <div class="zz" @click="handleColesZZ"></div>
    </div>
    <!-- 商品展示 -->
    <van-overlay v-if="goodsShow" :show="goodsShow" z-index="10">
      <div class="wrapper">
        <div class="goods-block">
          <div class="goods-buy-info">
            <div class="goods-buy-item"></div>
            <p class="goods-buy-item-order-id colorBrown">
              {{ $t("newAdd.OrderNum") }}{{ goodsInfo.order_no }}
            </p>
            <div class="goods-info-content">
              <div class="goods-info-image-box">
                <img class="goods-info-image" v-if="goodsInfo.product_picture"
                  :src="`${domain}${goodsInfo.product_picture}`" />
              </div>
              <div class="goods-info-item">
                <div class="goods-info-item-name">
                  <div class="goods-info-item-name-relative">
                    <p>{{ goodsInfo.product_name }}</p>
                  </div>
                </div>
                <div class="goods-info-item-info">
                  <div class="goods-info-item-info-monye">
                    {{ goodsInfo.price }}
                  </div>
                  <div class="goods-info-item-info-num">
                    X{{ goodsInfo.amount }}
                  </div>
                </div>
              </div>
            </div>
            <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.TotalOrderPrice") }}</span>
              <p class="goods-info-money">{{ goodsInfo.total_price }}</p>
            </div>
            <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.commission") }}</span>
              <p class="goods-info-commission colorBrown">
                {{ (goodsInfo.total_price * goodsInfo.rate) / 100 }}
              </p>
            </div>
            <div class="goods-buy-item expected-item">
              <span>{{ $t("newAdd.ExpectedReturn") }}</span>
              <p class="goods-info-money">
                {{
                  Number(goodsInfo.total_price) +
                  (goodsInfo.total_price * goodsInfo.rate) / 100
                }}
              </p>
            </div>
          </div>
          <div class="pop_btn" @click="handleSubmitNow">
            {{ $t("newAdd.SubmitNow") }}
          </div>
          <div class="close-btn" @click="goodsShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <van-overlay v-if="identifyShow" :show="identifyShow" z-index="10">
      <div class="wrapper">
        <div class="goods-block">
          <div class="goods-buy-info">
            <div class="goods-buy-item"></div>
            <!-- <p class="goods-buy-item-order-id colorBrown">
              {{ $t("OrderNum") }}{{ identifyGoodInfo.id }}
            </p> -->
            <div class="goods-info-content">
              <div class="goods-info-image-box">
                <img class="goods-info-image" v-if="identifyGoodInfo.goods_image"
                  :src="`${identifyGoodInfo.goods_image}`" />
              </div>
              <div class="goods-info-item">
                <div class="goods-info-item-name">
                  <div class="goods-info-item-name-relative">
                    <p>{{ identifyGoodInfo.goods_name }}</p>
                  </div>
                </div>
                <div class="goods-info-item-info">
                  <div class="goods-info-item-info-monye">
                    {{ identifyGoodInfo.goods_price }}
                  </div>
                  <!-- <div class="goods-info-item-info-num">
                    X{{ goodsInfo.amount }}
                  </div> -->
                </div>
              </div>
            </div>
            <!-- <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.TotalOrderPrice") }}</span>
              <p class="goods-info-money">{{ goodsInfo.total_price }}</p>
            </div>
            <div class="goods-buy-item">
              <span class="colorBrown">{{ $t("newAdd.commission") }}</span>
              <p class="goods-info-commission colorBrown">
                {{ (goodsInfo.total_price * goodsInfo.rate) / 100 }}
              </p>
            </div>
            <div class="goods-buy-item expected-item">
              <span>{{ $t("newAdd.ExpectedReturn") }}</span>
              <p class="goods-info-money">
                {{
                Number(goodsInfo.total_price) +
                (goodsInfo.total_price * goodsInfo.rate) / 100
                }}
              </p>
            </div> -->
          </div>
          <div class="pop_btn" @click="toThirdRecharge">
            {{ $t("newAdd.SubmitNow") }}
          </div>
          <div class="close-btn" @click="goodsShow = false"></div>
        </div>
      </div>
    </van-overlay>
    <!-- <Tabbar active="make-money" /> -->
  </div>
</template>

<script>
import {
  Checkbox,
  CheckboxGroup,
  Card,
  SubmitBar,
  Toast,
  Icon,
  List,
  Cell,
  progress,
  Popup,
  NoticeBar,
  CountDown,
  Overlay,
  NavBar,
  Swipe,
  SwipeItem,
  Field,
} from "vant";
import Tabbar from "@/components/Tabbar";
import { domain, $get } from "@/utils/request";
import Cookies from "js-cookie";
import { REQUEST_API } from "@/http/api";
import { countTime, getMyTime, deciTurnMoney } from "@/utils/tools";
import dataDetail from "@/utils/dataDetail";
import { formatDate } from "@/utils/tools";
import verConfig from "@/utils/verConfig";
import Br from "../../components/br.vue";
import getRandNoticeData from "@/utils/randNoticeData";
import HallMarquee from "@/components/hallMarquee.vue";
import { mapGetters } from 'vuex';
export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Icon.name]: Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [progress.name]: progress,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [CountDown.name]: CountDown,
    [Overlay.name]: Overlay,
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    Tabbar,
    Field,
    HallMarquee,
  },

  data() {
    return {
      verConfig,
      domain: domain,
      country: Cookies.get("language"),
      goodsInfo: {
        date: "2021-08-01 14:11:44",
        orderId: "202108010962489410",
        money: "500",
        commission: "700",
        expected: "6546",
        icon: "",
        name: "Chuột không dây sạc pin siêu mỏng 2.4GHz HXSJ M01Chuột không dây sạc pin siêu mỏng 2.4GHz HXSJ M01",
        num: "100",
      },
      goodsShow: false,
      gameGiftLists: [{ key: 0 }, { key: 1 }, { key: 2 }],
      makeRecordLists: [
        {
          label: this.$t("newAdd.AllocatCommissions"),
          value: "task_price",
        },
        {
          label: this.$t("newAdd.FrozenAmount"),
          value: "frozen_price",
        },
        // {
        //   label: this.$t("newAdd.TaskMatch"),
        //   value: "order_num",
        // },
        {
          label: this.$t("newAdd.AvailableBalance"),
          value: "money",
        },
      ],
      buyLoading: -1,
      listLoading: false,
      tableList: [
        {
          title: "V1",
        },
        {
          title: `2`,
        },
        {
          title: `3`,
        },
        {
          title: `4`,
        },
      ],
      statusBarList: [
        {
          label: `${this.$t("Cart.state.all")}`,
          key: null,
        },
        {
          label: `${this.$t("Cart.state.going")}`,
          key: "ing",
        },
        // {
        //   label: `${this.$t('Cart.state.paid')}`,
        //   key: 1,
        // },
        // {
        //   label: `${this.$t('Cart.state.frozen')}`,
        //   key: 5,
        // },
        {
          label: `${this.$t("Cart.state.over")}`,
          key: 2,
        },
      ],
      tableKey: 0,
      statusKey: null,
      moneyInfoList: [
        {
          label: `${this.$t("Cart.info.allMoney")}`,
          key: "allMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayAllOrder")}`,
          key: "dayAllOrder",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.dayTrustMoney")}`,
          key: "dayTrustMoney",
          value: 0,
        },
        {
          label: `${this.$t("Cart.info.yesterdayMoney")}`,
          key: "yesterdayMoney",
          value: 0,
        },
      ],
      list: [],
      loading: false,
      finished: false,
      isBuyBoxShow: false,
      isBuyFailShow: false,
      isBuyFailNotMoneyShow: false,
      percentage: 0,
      level: 1,
      pageNo: 1,
      buyGoodsForm: {}, // 购买当前商品信息，可用可不用
      popupShow: false,
      interval: "",
      pageNow: 1,
      newList: [],
      // userInfo: {},
      orderError: "",
      btnTime: 0,
      btnInterval: null,
      getOrderSuccess: true,
      rate: "",
      noticeArr: [],
      noticeShowIndex: 3,
      // levelData: [],
      orderNumObj: {
        0: 1,
        1: "1~3",
        2: "1~6",
        3: "1~7",
        4: "1~8",
        5: "1~9",
      },
      urlInput: "",
      identifyGoodInfo: {},
      identifyShow: false,
      rechargePath: this.verConfig.pathNewRecharge
        ? "third-recharge-n"
        : "new-recharge",
    };
  },

  computed: {
    ...mapGetters(["userInfo", "levels"]),
    // submitBarText() {
    //   const count = this.checkedGoods.length;
    //   return '结算' + (count ? `(${count})` : '');
    // },
    totalPrice() {
      return this.goods.reduce(
        (total, item) =>
          total + (this.checkedGoods.indexOf(item.id) !== -1 ? item.price : 0),
        0
      );
    },
  },
  created() {
    this.init();
    // this.getMoneyInfo();
    // this.getLevels()
    // this.getTaskOrder()
    // this.getUserInfo();
    // this.getLevel();
  },
  mounted() {
    if (this.$route.params.level) {
      this.tableKey = this.$route.params.level;
    } else {
      this.tableKey = 0;
    }
    this.initTitlePosition();
    // this.noticeIni();
    this.noticeArr = getRandNoticeData();
    // this.noticeDataUpdate();
  },
  methods: {
    randName() {
      let name =
        `+${this.verConfig.areaCode || ""}\n` +
        String(dataDetail.rand(90, 99)) +
        dataDetail.rand(3, 9) +
        "****" +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10);
      return name;
    },
    randMoney() {
      const interval = this.verConfig.homeProfitInterval || [100, 300000];
      let probability = dataDetail.rand(0, 100);
      let res = 0;
      if (probability <= 30) {
        // if(this.verConfig.homeProfitFormat){
        //   return this.moneyFormat(dataDetail.rand(interval[0], interval[1] / 20))
        // }
        return dataDetail.rand(interval[0], interval[1] / 20);
      } else {
        // if(this.verConfig.homeProfitFormat){
        //   return this.moneyFormat(dataDetail.rand(interval[1] / 20, interval[1]))
        // }
        return dataDetail.rand(interval[1] / 20, interval[1]);
      }
    },
    moneyFormat(money) {
      const arr = money.toString().split("").reverse();
      for (let i = 0, len = arr.length; i < Math.floor(len / 3); i++) {
        arr.splice(3 * (i + 1) + i, 0, ".");
      }
      if (arr[arr.length - 1] === ".") {
        arr.pop();
      }
      return arr.reverse().join("");
    },
    noticeIni() {
      for (let i = 0; i < 5; i++) {
        this.noticeArr.push({
          name: this.randName(),
          money: this.randMoney(),
        });
      }
    },
    noticeDataUpdate() {
      setInterval(() => {
        this.noticeArr.splice(this.noticeShowIndex, 1, {
          name: this.randName(),
          money: this.randMoney(),
        });
        if (this.noticeShowIndex >= 4) {
          this.noticeShowIndex = 1;
        } else {
          this.noticeShowIndex++;
        }
      }, 3000);
    },
    dateEvent(indate) {
      console.log(indate);
      return formatDate("", indate);
    },
    progress60() {
      let auth_timetimer = setInterval(() => {
        this.percentage++;
        if (this.percentage > 30) {
          clearInterval(auth_timetimer);
        }
      }, 20);
      let auth_timetimer2 = setInterval(() => {
        if (this.percentage >= 31 && this.percentage <= 70) {
          this.percentage++;
        }
        if (this.percentage > 60) {
          clearInterval(auth_timetimer2);
        }
      }, 80);
    },
    getUserInfo() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      this.$api.getUserInfo(
        (res) => {
          // this.userInfo = res;
          this.makeRecordLists[2].value = this.userInfo.order_num;
          this.makeRecordLists[0].value = this.userInfo.task_price;
          this.makeRecordLists[1].value = this.userInfo.frozen_price;
          this.makeRecordLists[3].value = this.userInfo.money;
          // this.makeRecordLists[2].value =
          //   this.userInfo.task_status === 2
          //     ? 0
          //     : this.userInfo.order_num - this.userInfo.complete_order;
        },
        (err) => {
          Toast.clear();
        },
        (r) => {
          this.$api.getLevel((res) => {
            this.makeRecordLists[0].value =
              (this.userInfo.task_price * res[this.userInfo.level].rate) / 100;
            this.rate = res[this.userInfo.level].rate;
            Toast.clear();
          });
        }
      );
    },
    getLevel() {
      this.$api.getLevel((res) => {
        this.levelData = res;
        Toast.clear();
      });
    },
    /** handle **/
    handleWithOrder() { },
    handleSubmitNow() {
      this.$router.push({ name: "cart" });
    },
    handleFindExplain() {
      this.popupShow = true;
    },
    handleTable(index) {
      this.listLoading = true;
      if (index === this.tabelKey) {
        return false;
      }
      this.tableKey = index;
      this.getTaskOrder();
      // let list = [
      //   'SliverMember',
      //   'GoldMember',
      //   'PlatinumMember',
      //   'DiamondMember',
      // ]
      // list.forEach((_, i) => {
      //   if (this.tabelKey === _) {
      //     this.level = i + 1
      //     this.list = []
      //     this.finished = false
      //     this.pageNo = 1
      //   }
      // })
    },
    statusSearch(item) {
      this.newList = [];
      this.listLoading = true;
      if (item.key === this.statusKey) {
        return false;
      }
      this.statusKey = item.key;
      this.getTaskOrder();
    },
    getCommissionRate(level) {
      if (!level) return "0%";
      const { rate } = level;
      const rateArr = rate.split("-");
      if (rateArr.length === 1) return rate + "%";
      return `${Math.min.apply(null, rateArr)}%~${Math.max.apply(
        null,
        rateArr
      )}%`;
    },
    handleBuy(item) {
      if (this.$cookie.get("token")) {
        // if (
        //   this.userInfo.order_num - this.userInfo.complete_order &&
        //   this.userInfo.task_status !== 2
        // ) {
        this.isBuyBoxShow = true;
        this.percentage = 0;
        this.$api.getTask(
          { level: this.userInfo.level },
          (res) => {
            this.getOrderSuccess = true;
            let auth_timetimer3 = setInterval(() => {
              if (this.percentage >= 61 && this.percentage < 100) {
                this.percentage++;
              }
              if (this.percentage === 100) {
                clearInterval(auth_timetimer3);
                this.goodsInfo = res;
                this.updateUserInfo();
                setTimeout(() => {
                  this.isBuyBoxShow = false;
                  // 打开订单提示框
                  this.goodsShow = true;
                }, 300);
              }
            }, 30);
          },
          (err) => {
            this.getOrderSuccess = false;
            this.interval = setInterval(() => {
              this.$api.getTask(
                { level: this.userInfo.level },
                (r) => {
                  this.getOrderSuccess = true;
                  let auth_timetimer3 = setInterval(() => {
                    if (this.percentage >= 61 && this.percentage < 100) {
                      this.percentage++;
                    }
                    if (this.percentage === 100) {
                      clearInterval(auth_timetimer3);
                      this.goodsInfo = r;
                      this.updateUserInfo();
                      this.orderError = "";
                      setTimeout(() => {
                        this.isBuyBoxShow = false;
                        // 打开订单提示框
                        this.goodsShow = true;
                      }, 300);
                    }
                  }, 30);
                  clearInterval(this.interval);
                },
                (e) => {
                  this.getOrderSuccess = false;
                  this.orderError = e;
                }
              );
            }, 3000);
            let auth_timetimer3 = setInterval(() => {
              if (this.percentage >= 61 && this.percentage < 99) {
                this.percentage++;
              }
              if (this.percentage === 99) {
                clearInterval(auth_timetimer3);
                /**
                    setTimeout(() => {
                      clearInterval(this.interval);
                      setTimeout(()=>{
                        if(!this.getOrderSuccess){
                          if(this.orderError == ''){
                            this.$toast(this.$t('newAdd.NotBeenSentOrder'));
                          }else{
                            this.$toast(this.orderError)
                          }
                        }
                        this.orderError = '';
                        // 按钮允许点击
                        this.isBuyBoxShow = false;
                      },1000)
                    }, 29000) */
                // 新修改去除长时间等待
                clearInterval(this.interval);
                setTimeout(() => {
                  if (!this.getOrderSuccess) {
                    if (this.orderError == "") {
                      this.$toast(this.$t("newAdd.NotBeenSentOrder"));
                    } else {
                      this.$toast(this.orderError);
                    }
                  }
                  this.orderError = "";
                  // 按钮允许点击
                  this.isBuyBoxShow = false;
                }, 500);
              }
            }, 100);
          }
        );
        this.progress60();
        // } else {
        //   this.$toast(this.$t("newAdd.TodayOrderOver"));
        // }
      } else {
        this.$toast(this.$t("newAdd.NotLogin"));
        setTimeout(() => {
          this.$router.replace({ name: "login" });
        }, 1000);
      }
    },
    async Identify() {
      if (!this.urlInput) return;
      Toast.loading();
      try {
        const res = await $get("/third/parse/url", { url: this.urlInput });
        Toast.clear();
        const { ret, data, msg } = res.data;
        if (ret === 1) {
          this.identifyGoodInfo = data;
          this.identifyShow = true;
          return;
        }
        Toast(msg);
      } catch (error) {
        Toast.clear();
      }
    },
    toThirdRecharge() {
      if (this.ver == "amigo_vi") {
        this.$router.push("/cart");
        return;
      }
      let pathUrl = "/ThirdRecharge";
      let type = "third";
      if (this.verConfig.pathNewRecharge) {
        pathUrl = "/third-recharge-n";
      }
      this.$router.push(
        `${pathUrl}?type=${type}&data=${JSON.stringify(this.identifyGoodInfo)}`
      );
      localStorage.setItem(
        "rechargeGoodDataInfo",
        JSON.stringify({ data: this.identifyGoodInfo, type: "third" })
      );
    },
    handleBuyPercentage(item) {
      if (this.percentage < 99 && this.btnTime !== 0) {
        return false;
      } else {
        this.$api.toService();
      }
      // this.sendBuy(this.buyGoodsForm.id)
    },
    handleInviteFriends() {
      this.$router.push("InviteFriends"); // 邀请好友
    },
    handleBuyVip() {
      this.isBuyFailShow = false;
      // console.log('立即购买VIP')
      this.$router.push("Recharge");
    },
    updateUserInfo() {
      if (this.$cookie.get("userInfo")) {
        let userInfo = JSON.parse(this.$cookie.get("userInfo"));
        delete userInfo.level;
        this.$cookie.set("userInfo", JSON.stringify(userInfo));
        this.$api.getUserInfo();
      }
    },
    handlecolsePopBox() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    handleColesZZ() {
      this.isBuyBoxShow = false;
      this.isBuyFailShow = false;
      this.isBuyFailNotMoneyShow = false;
      this.buyGoodsForm = {};
    },
    /** api **/
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          this.list.push(this.list.length + 1);
        }
        // 加载状态结束
        this.loading = false;
        // 数据全部加载完成
        if (this.list.length >= 40) {
          this.finished = true;
        }
      }, 1000);
    },
    getGoodsList() {
      if (this.finished) {
        return false;
      }
      let form = {
        // page: this.pageNo,
        // pageNum: 10,
        uid: this.userInfo[1].data.player.uid,
        level: this.level,
        cmd: "201002",
      };
      REQUEST_API(form)
        .then((r) => {
          // console.log(r);
          if (!r.data.sc) {
            // console.log(r.data.notifyList[0].data)
            if (r.data.notifyList[0].data.length) {
              this.list.push(...r.data.notifyList[0].data);
              // this.finished = r.data.notifyList[0].data.page === r.data.notifyList[0].data.pageSize
              this.finished = true;
              this.pageNo++;
              if (this.finished) {
                this.loading = false;
              }
            } else {
              this.list = [];
              this.loading = false;
              this.finished = true;
            }
          } else {
            Toast(this.$t("Hint.failed"));
            this.list = [];
            this.loading = false;
            this.finished = true;
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    getMoneyInfo() {
      let form = {
        uid: this.userInfo[1].data.player.uid,
        cmd: "201004",
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            let moneyForm = r.data.notifyList[0].data;
            // console.log(moneyForm);
            this.moneyInfoList[0].value = deciTurnMoney(moneyForm.diamond) || 0;
            this.moneyInfoList[1].value = moneyForm.size || 0;
            this.moneyInfoList[2].value = deciTurnMoney(moneyForm.price) || 0;
            this.moneyInfoList[3].value =
              deciTurnMoney(moneyForm.last_reward) || 0;
            // console.log(this.moneyInfoList);
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    sendBuy(id) {
      let form = {
        id,
        cmd: "201003",
        uid: this.userInfo[1].data.player.uid,
      };
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            Toast(this.$t("Hint.success"));
            this.handleColesZZ();
            this.list = [];
            this.finished = false;
            this.pageNo = 1;
            this.getMoneyInfo();
          } else if (r.data.sc === 1002) {
            this.isBuyBoxShow = false;
            // this.isBuyFailShow = true
            this.isBuyFailNotMoneyShow = true;
            Toast(this.$t("Hint.failed"));
          } else if (r.data.sc === 1211) {
            Toast(this.$t("Hint.goodsNumNo"));
          } else {
            Toast(this.$t("Hint.failed"));
          }
        })
        .catch((e) => {
          console.info(e);
        });
    },
    /** 其他 **/
    init() {
      // this.userInfo = this.getUserInfoNew();
      this.makeRecordLists[3] &&
        (this.makeRecordLists[3].value = this.userInfo.money);
      this.level = this.$route.query.id ? Number(this.$route.query.id) + 1 : 1;
      this.tableList.forEach((_, i) => {
        if (i + 1 === this.level) {
          this.tabelKey = _.key;
        }
      });
    },
    getUserInfoNew() {
      let val = this.$cookie.get("userInfo");
      if (val) {
        let value = JSON.parse(this.$cookie.get("userInfo"));
        if (Object.keys(value).length) {
          return value;
        } else {
          return { money_not: "", money: "0" };
        }
      } else {
        return { money_not: "", money: "0" };
      }
    },
    initTitlePosition() {
      if (this.tableKey > 2) {
        let title_wrap = document.querySelector(".classify");
        title_wrap.scrollLeft = document.documentElement.clientWidth;
      }
    },
    /** 抢单 */
    rushOrder() {
      // this.isBuyBoxShow = true;
      // setTimeout(()=>{
      //   this.isBuyBoxShow = false;
      // },5000)
      // this.$api.getTask(res=>{
      //   console.log(res);
      //   this.isBuyBoxShow = false;
      // },err=>{
      // });
    },
    getLevels() {
      if (this.$cookie.get("levels")) {
        this.tableList = JSON.parse(this.$cookie.get("levels"));
      }
      this.tableKey = 0;
    },
    closeBuy() {
      this.isBuyBoxShow = false;
      this.getOrderSuccess = true;
      clearInterval(this.interval);
    },
    getTaskOrder() {
      if (this.finished) {
        return false;
      }
      let data = {
        level: this.tableKey,
        page: this.pageNow,
        status: this.statusKey,
      };
      this.$api.getTaskOrder(
        data,
        (res) => {
          this.newList = res.lists;
          this.listLoading = false;
        },
        (err) => {
          this.newList = [];
          this.listLoading = false;
        }
      );
    },
    toPay(row, index) {
      this.buyLoading = index;
      // this.newList[index].buyLoading = true;
      this.$api.payTaskOrder(
        row.id,
        (res) => {
          this.getTaskOrder();
          this.buyLoading = -1;
        },
        (err) => {
          console.log(err);
          if (err.ret == 0) {
            this.$toast(err.msg);
            setTimeout(() => {
              if (this.verConfig.pathNewRecharge)
                this.$router.push({ name: "thirdRechargeNew" });
              else this.$router.push({ name: "NewRecharge" });
            }, 1000);
          } else {
            if (err.msg) {
              this.$toast(err.msg);
            }
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

@background: var(--nav-background);

.wrap {
  min-height: 100vh;
  background-color: @background;
}

.top_bar {
  overflow: hidden;
  
  .title {
    background: var(--bg);
    color: var(--theme);
    height: calc(49rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: calc(18rem / 16);
    position: relative;
    width: 100%;
  }

  .content {
    margin: calc(11rem / 16);
    padding: calc(20rem / 16);
    border-radius: 8px;
    background-color: var(--bg);

    .top {
      display: flex;
      align-items: center;
      margin-bottom: calc(12rem / 16);

      .left {
        font-size: calc(18rem / 16);
      }

      .gt {
        font-size: calc(20rem / 16);
        font-weight: 600;
        margin: 0 calc(20rem / 16);
        // width: calc(10rem / 16);
        // overflow: hidden;
      }

      .tag {
        padding: 0 5px;
        text-align: center;
        line-height: calc(20rem / 16);
        background-color: #d6b075;
        border-radius: 4px;
        color: #fff;
        height: calc(20rem / 16);
        font-size: calc(12rem / 16);
      }
    }

    .content_item {
      border-bottom: 1px solid #eeeff2;

      &:nth-last-of-type(1) {
        border-bottom: none;
      }

      display: flex;
      align-items: center;

      .items {
        margin-left: calc(16rem / 16);
        line-height: 1.2;

        &:nth-of-type(1) {
          margin: 0;
        }

        .title {
          font-size: calc(14rem / 16);
          color: #999;
        }

        .value {
          line-height: 1.6;
          display: flex;
          align-items: center;

          >span {
            font-size: calc(14rem / 16);
          }

          font-size: calc(20rem / 16);
        }
      }
    }
  }
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.spancolor {
  color: @Color;
}

.wrapper {
  .flex();
  height: 100vh;

  .goods-info-image {
    width: 20vw;
    // float: left;
  }

  .goods-buy-item,
  .goods-info-item-info {
    line-height: 1.5em;
    .flex(space-between);
  }

  .goods-info-item {
    font-size: 14px;

    .goods-info-item-info {
      padding: 4vw 0;
    }
  }

  .goods-block {
    .colorBrown {
      color: #a8a8a8;
    }

    width: 70vw;
    // height: 80vh;
    background-color: #ceb198;
    border-radius: 5vw;
    padding: 5vw 2vw 6vw 2vw;

    .goods-buy-info {
      .goods-buy-item-order-id {
        padding: 4vw 0;
        font-style: 13px;
      }

      .goods-info-content {
        display: flex;
      }

      font-size: 15px;
      padding: 3vw;
      border-radius: 2vw;
      background-color: #fff;

      .goods-info-money {
        color: #ff9307;
      }

      .goods-info-image {
        width: calc(80rem / 16);
        height: calc(88rem / 16);
      }
    }
  }

  .pop_btn {
    background-color: #ff3c00;
    width: 80%;
    margin: 0 auto;
    margin-top: 5vw;
    text-align: center;
    padding: 3vw 0;
    border-radius: 2vw;
    color: var(--btnsizeColor);
  }
}

.content {
  background: var(--nav-background);

  .content_title {
    img {
      width: 100%;
    }
  }

  .game-btn {
    width: 90%;
    text-align: center;
    background-color: var(--themes);
    margin: 0 auto;
    color: var(--btnsizeColor);
    padding: 2.667vw 0;
    border-radius: 999px;
    margin-top: 20px;
  }

  .textarea_wrap {
    width: 90%;
    margin: 20px auto;

    .textarea {
      border-radius: 6px;
    }

    /deep/ textarea {
      color: #4f5860;
    }

    .submit_btn {
      text-align: center;
      background: linear-gradient(to right, #0dccff, #3c7bca);
      margin: 0 auto;
      color: #fff;
      padding: 2.667vw 0;
      border-radius: 999px;
      margin-top: 20px;
    }
  }

  .make-record {
    background-color: #fff;
    width: 85%;
    border-radius: 1vw;

    margin: 0 auto;
    padding: 4.8vw 1.333vw 2.667vw 4vw;
    box-shadow: 0 0.533vw 1.333vw 0 rgba(255, 255, 255, 0.1);
    margin-top: 20px;
    font-size: 12px;

    .make-record-lists {
      text-align: center;
      .flex(center, wrap);

      .make-record-item {
        .make-record-item-icon {
          width: 20px;
          height: 20px;
        }

        padding: 10px;
      }
    }
  }

  // 下单说明
  .make-synopsis {
    width: 100vw;
    background-color: #fff;
    margin-top: 20px;
    font-size: 3.733vw;

    .make-synopsis-title {
      text-align: center;
      color: @Color;
      padding: 10px 0;
    }

    .make-synopsis-content {
      padding: 0 20px 20px 20px;
    }
  }

  .marquee_wrap {
    display: flex;
    background-color: #fff;
    padding: calc(6rem / 16) 4vw calc(6rem / 16) 4vw;
    box-shadow: 0 0.533vw 1.333vw 0 rgba(255, 255, 255, 0.1);
    align-items: center;
    border-radius: 6px;
    margin: calc(11rem / 16);

    .icon {
      font-size: calc(24rem / 16);
      margin-right: calc(14rem / 16);
    }
  }

  // 轮播
  .marquee {
    // width: 85%;
    // margin: 0 auto !important;
    // margin-top: 20px;
    // height: calc(34rem / 16);
    // margin-left: 5vw;
    border-radius: 1vw;

    .halloffame {
      font-size: 12px;
      line-height: 3em;
    }
  }

  .buy-box {
    width: 100%;
    height: 100%;
    // position: relative;
    position: fixed;
    // top: -100%;
    top: 0;

    >.cont {
      width: calc(350rem / 16);
      height: calc(460rem / 16);
      // background: url('../../assets/Img/goods/img/DDSC_0001_xdz.png') no-repeat;
      position: absolute;
      z-index: 44;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      >div {
        width: calc(350rem / 18);
        height: calc(460rem / 18);
        margin: 0 auto;
        background: url("../../assets/Img/goods/img/DDSC_0001_xdz.png") no-repeat;
        background-size: 100% 100%;

        >.goodsInfo {
          padding-top: calc(250rem / 16);
          display: flex;
          align-items: center;
          // justify-content: space-between;
          margin-bottom: calc(5rem / 16);
          position: relative;

          >.close {
            position: absolute;
            right: calc(10rem / 16);
            top: calc(120rem / 16);
          }

          >div {
            display: flex;
            align-items: center;
            font-size: calc(13rem / 16);
            color: #9b8383;

            >img {
              width: calc(30rem / 16);
              height: calc(30rem / 16);
              border-radius: 6px;
            }

            >div {
              display: flex;
              flex-direction: column;
              margin-left: calc(15rem / 16);
              width: calc(70rem / 16);

              >span {}
            }
          }

          >p {
            font-size: calc(18rem / 16);
            text-align: center;
            color: #9b8383;
            // margin-bottom: calc(2rem / 16);
            margin: 0 0 calc(2rem / 16) calc(15rem / 16);
          }
        }

        >div {
          width: 98%;
          margin-left: calc(6rem / 16);
          // margin: auto;
        }

        >span {
          font-size: calc(18rem / 16);
          display: block;
          text-align: center;
          color: #9b8383;
          // margin: calc(15rem / 16) calc(22rem / 16) calc(2rem / 16) 0;
          margin: calc(15rem / 16) 0 calc(2rem / 16) 0;
        }

        >button {
          width: calc(100% - (60rem / 16));
          height: calc(60rem / 16);
          margin: auto;
          display: block;
          // margin-right: calc(40rem / 16);
          border-radius: 30px;
          border: 0;
          color: #fff;
          background: #c4cec9;
          font-size: calc(22rem / 16);
          font-weight: 200;

          &.on {
            background: #48db8d;
          }
        }
      }
    }
  }
}
</style>
